import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import GradientText from "@components/common/gradient-text"
import Flex from "@components/elements/flex"
import Image from "@components/image"
import Anchor from "@components/elements/anchor"
import Perks from "@components/common/perks"
import { useTheme } from "styled-components"

const CareersPage = ({ data }) => {
  const listings = data.careers.edges
  const { color } = useTheme()
  
  return (
    <Layout
      fullWidth
      title={`Careers`}
      description={`Join our mission to help people succeed with data.`}
      marginless
    >
      <Container relative fullWidth noPadding hideOverflow>
        <Div style={{ margin: '10rem auto 0 auto', maxWidth: '900px'}}>
          <Image eager style={{zIndex: -1}} file={data.team} />
        </Div>
        <Flex margin="4rem auto 0rem" center textContainer gap="2rem">
          <Heading center fontSize="3rem">
            Join our mission to help <br />
            <GradientText gradient="linear-gradient(180deg, #6E79E2 -25%, #D03D84 125%)">
              people succeed with data
            </GradientText>
          </Heading>
          <Paragraph>
            Klipfolio was founded in Ottawa, Canada and built by an amazing team
            of data-loving people who appreciate clean, intuitive software
            design and understand the role analytics plays in the success of a
            business.
          </Paragraph>
          <Paragraph>
            While we appreciate the benefits of working from home with flexible
            schedules, we&apos;re a close-knit bunch who truly enjoy spending
            time together. Social events and in-person company updates provide
            the perfect balance. Want to learn more about us?
            <br />
            <br />
            <Anchor link="/about">See our mission and company values</Anchor>
          </Paragraph>
        </Flex>

        <Flex center padding="8rem 0"  textContainer gap="2rem">
          <Heading as="h2">
            Open&nbsp;
            <GradientText gradient="linear-gradient(180deg, #6E79E2 -25%, #D03D84 125%)">
              Positions
            </GradientText>
          </Heading>
          {!listings ? 
            <>
              <Paragraph>
                We have filled all of our current opportunities. If you would like
                to be considered for one in the future, please send your resume to&nbsp;
                <Anchor link="mailto:careers@klipfolio.com">
                  careers@klipfolio.com
                </Anchor>
              </Paragraph>
              <Paragraph>
                Klipfolio provides accommodations for applicants with health
                conditions or impairments. If you have a disability or medical need,
                please contact us at&nbsp;
                <Anchor link="mailto:accessibility@klipfolio.com">
                  accessibility@klipfolio.com
                </Anchor>&nbsp;to discuss arrangements.
              </Paragraph> 
            </>
          : 
            <Flex background={color.indigo100} padding="2rem" borderRadius="1rem">
              {listings.map(({ node: listing }, i) => {
                return (
                  <div key={`listing-${i}`}>
                    <Heading as="h5" margin="0 0 1rem">
                      {listing.title}
                    </Heading>
                    <Anchor
                      link={`/about/careers/${listing.slug}`}
                      margin="auto 0 0"
                      fontSize="1rem"
                      arrow
                    >
                      View Position
                    </Anchor>
                  </div>
                )
              })}
            </Flex>
          }
        </Flex>

        <Flex center padding="0 0 8rem" textContainer gap="2rem">
          <Div>
            <Heading margin="0 0 1rem" as="h2">
              Working at&nbsp;
              <GradientText gradient="linear-gradient(180deg, #6E79E2 -25%, #D03D84 125%)">
                Klipfolio
              </GradientText>
            </Heading>
            <Heading as="h3" fontSize="1.3rem" fontWeight="500">
              An innovative, driven, supportive environment to build your career
            </Heading>
          </Div>
          <Paragraph>
            When you join Klipfolio, you immerse yourself in a collaborative
            atmosphere that values experimentation and views mistakes as
            valuable learning experiences. Here, your professional development
            is fostered in many ways, including formal training, mentorship,
            coaching, engaging workshops, and hack days that inspire creative
            thinking and team building.
          </Paragraph>
          <Paragraph>
            You&apos;ll be given opportunities to learn and grow, not only
            within your current areas of expertise but well beyond, encouraging
            you to continually evolve and thrive.
          </Paragraph>
          <Paragraph>
            We understand the importance of maintaining a work-life balance and
            acknowledging your contributions over the long term. That&apos;s why
            we offer a variety of perks to support your well-being and recognize
            your dedication.
          </Paragraph>
        </Flex>

        <Div margin="0 auto 8rem" container>
          <Heading as="h2" center margin="0 0 3rem">
            Our&nbsp;
            <GradientText gradient="linear-gradient(180deg, #6E79E2 -25%, #D03D84 125%)">
              Perks
            </GradientText>
          </Heading>
          <Perks />
        </Div>
      </Container>
    </Layout>
  )
}

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CareersPage

export const pageQuery = graphql`
  query CareersQuery {
    careers: allCareers(filter: { status: { eq: "published" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    gradient: directusFiles(
      directus_id: { eq: "b143ae2d-fab4-4821-b1a9-c3766fcf9317" }
    ) {
      cdn
      directus_id
      id
      placeholder
      title
    }
    team: directusFiles(
      directus_id: { eq: "f6776cae-46ea-4502-b94d-679a134f0e0f" }
    ) {
      cdn(width: 2500, quality: 100)
      directus_id
      id
      placeholder
      title
    }
    users: allOurUsers {
      edges {
        node {
          company {
            title
            cdn
            placeholder
            directus_id
          }
        }
      }
    }
  }
`
